import { Component, Input, ViewChild } from '@angular/core';
import { TemplateItemTemplate } from '../../../../../Model/Catalog/TemplateItemTemplate';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';
import { lastValueFrom } from 'rxjs';
import { WawiListService } from '../../../../../Services/wawiList.service';
import { ModelService } from '../../../../../Services/model.service';
import { WawiList } from '../../../../../Model/wawi/WawiList';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeListComponent } from 'devextreme-angular';

@Component({
  selector: 'wawiMappingChooser',
  templateUrl: './wawiMappingChooser.component.html',
  styleUrls: ['./wawiMappingChooser.component.css']
})
export class WawiMappingChooserComponent {
  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
  @Input() set exportParameter(value: ExportParameter) {
    this._exportParameter = value;
    this.selectFieldsFromConfig();
  }

  get exportParameter() {
    return this._exportParameter;
  }

  @Input() set fields(value: TemplateItemTemplate[]) {
    this._fields = value;
    this.fillDataSource();
  }

  get fields(): TemplateItemTemplate[] {
    return this._fields;
  }

  @Input() set exportMode(value: boolean) {
    if (value != this.exportMode) {
      this._exportMode = value;
      this.updatePreviews();
    }
  }

  get exportMode(): boolean {
    return this._exportMode;
  }

  @Input() set fieldGroups(value: string[]) {
    this._fieldGroups = value;
    this.fillDataSource();
    this.updatePreviews();
  };

  get fieldGroups(): string[] {
    return this._fieldGroups;
  }

  _fieldGroups: string[];
  _fields: TemplateItemTemplate[];
  _exportParameter: ExportParameter;
  _exportMode: boolean;
  wawiMappings: WawiList[];
  dataSource: TreeListRow[] = [];
  selectedKeys: string[] = [];

  constructor(public modelService: ModelService, public wawiListService: WawiListService, public translate: TranslateService) {

  }

  fillDataSource() {
    if (!this.fieldGroups || !this.fields || this.fieldGroups.length == 0 || this.fields.length == 0)
      return;

    this.dataSource = this.fieldGroups.map(x => {
      return { field: x, translatedField: this.translate.instant(x) }
    });
    this.fields.forEach(x => {
      var groupItem = this.dataSource.find(y => y.field == x.group);
      if (groupItem)
        this.dataSource.push({ parentId: groupItem.field, field: x.field, translatedField: this.translate.instant(x.field) });
    });
  }

  mappingChanged(event) {
    this.updatePreviews(event.data);
    this.updateExportParameter(event);
  }

  updateExportParameter(event) {
    var mappedList = event.component.getSelectedRowsData("leavesOnly").map(x => [ x.field, x.mappingId ?? "" ]);
    this.exportParameter.fieldsToMappingIds = Object.fromEntries(mappedList);
  }

  async updatePreviews(rowData: TreeListRow = undefined) {
    if (!this.wawiMappings) {
      this.wawiMappings = await lastValueFrom(this.wawiListService.getAllMappingsWithoutFields(this.modelService.loginService.currentUser.customerId));
    }
    if (!rowData) {
      this.dataSource.forEach(x => {
        if (x.mappingId) {
          let wawiList = this.wawiMappings.find(y => y.id == x.mappingId);
          if (!wawiList) {
            x.preview = undefined;
            return;
          }
          let leftSidePreviewText = this.exportMode ? wawiList.key : wawiList.value;
          let rightSidePreviewText = this.exportMode ? wawiList.value : wawiList.key;
          x.preview = `${leftSidePreviewText} -> ${rightSidePreviewText}`;
        }
      });
    } else {
      if (rowData.parentId) {
        let wawiList = this.wawiMappings.find(y => y.id == rowData.mappingId);
        if (!wawiList) {
          rowData.preview = undefined;
          return;
        }
        let leftSidePreviewText = this.exportMode ? wawiList.key : wawiList.value;
        let rightSidePreviewText = this.exportMode ? wawiList.value : wawiList.key;
        rowData.preview = `${leftSidePreviewText} -> ${rightSidePreviewText}`;
      } else {
        this.dataSource.forEach(x => {
          if (x.parentId == rowData.field || x.field == rowData.field) {
            x.mappingId = rowData.mappingId;
            let wawiList = this.wawiMappings.find(y => y.id == x.mappingId);
            if (!wawiList) {
              x.preview = undefined;
              return;
            }
            let leftSidePreviewText = this.exportMode ? wawiList.key : wawiList.value;
            let rightSidePreviewText = this.exportMode ? wawiList.value : wawiList.key;
            x.preview = `${leftSidePreviewText} -> ${rightSidePreviewText}`;
          }
        });
      }
    }
  }

  selectFieldsFromConfig() {
    this.selectedKeys = [];
    this.fillDataSource();
    if (!this.exportParameter?.fieldsToMappingIds || this.dataSource.length == 0)
      return;

    for (let field in this.exportParameter.fieldsToMappingIds) {
      let mappingId = this.exportParameter.fieldsToMappingIds[field];
      var fieldRow = this.dataSource.find(x => x.field == field);
      fieldRow.mappingId = mappingId;
      this.selectedKeys.push(field);
    }
    this.updatePreviews();
  }
}

type TreeListRow = {
  parentId?: string,
  field: string,
  translatedField: string,
  mappingId?: string,
  preview?: string
};
